.page {
  text-align: center;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.contact-image {
  width: 150px;  /* Size of the square image */
  height: 150px;  /* Make it a square */
  object-fit: cover;  /* Ensure the image covers the area without distortion */
  border-radius: 8px;  /* Rounded corners for a sleek look */
  margin-bottom: 20px;  /* Space between the image and the header */
}

h1 {
  font-size: 32px;
  color: #000000;
  margin-bottom: 20px;
}

p {
  font-size: 22px;
  color: #000000;
  margin-bottom: 30px;
  max-width: 600px;
}

button {
  padding: 12px 20px;
  font-size: 16px;
  color: white;
  background-color: #000000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #45a049;
}
