.page {
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  box-sizing: border-box;
}

.pfpCanvas {
  position: relative;
  width: 250px;  /* Canvas size */
  height: 250px;
  margin: 20px 0;
  border: 1px solid #ccc;  /* Border around the canvas */
  background-color: #fff;  /* Canvas background color */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);  /* Subtle shadow effect */
}

.pfpCanvas img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;  /* Ensure images are contained and don't stretch */
}

h1 {
  font-size: 26px;
  color: #000000;
  margin-bottom: 20px;
  font-weight: bold;
}

.dropdownContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;  /* Limit width of the dropdown container */
}

.dropdownGroup {
  margin: 12px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

label {
  font-size: 14px;
  color: #000000;
  margin-right: 10px;  /* Space between the label and the button */
}

select {
  padding: 8px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #fff;
  color: #333;
  transition: border 0.3s ease, box-shadow 0.3s ease;
  margin-top: 5px;
}

select:hover {
  border-color: #45a049;  /* Highlight border on hover */
}

select:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.5); /* Focus effect */
}

.actionButtons {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 300px;
}

button {
  padding: 12px 18px;
  font-size: 14px;
  color: white;
  background-color: #000000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  min-width: 120px;  /* Ensure buttons are not too small */
}

button:hover {
  background-color: #45a049;  /* Button hover color */
}

@media (max-width: 600px) {
  .pfpCanvas {
    width: 200px;
    height: 200px;
  }

  button {
    font-size: 12px;
    padding: 8px 14px;
    min-width: 100px;  /* Ensure buttons have reasonable size on mobile */
  }

  select {
    font-size: 12px;
    padding: 6px;
  }

  .dropdownContainer {
    max-width: 100%;
  }
}
