/* Importing and defining the custom font */
@font-face {
  font-family: 'CustomFont'; /* Give it a name */
  src: url('../public/font.ttf') format('truetype'); /* Path to the font file in public folder */
  font-weight: normal;
  font-style: normal;
}

/* General Styles for the App */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;
  font-family: 'CustomFont', Arial, sans-serif; /* Apply custom font and fall back to Arial */
  overflow: hidden;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;
}

/* Home Background */
.home-bg {
  background-image: url('../public/bg.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  color: white;
  height: 100vh;
}

/* Solid Background */
.solid-bg {
  background-color: #57a84f;
  height: 100vh;
  color: white;
}

/* Home Button Styles */
.home-button {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}

.home-button a {
  color: white;
  font-size: 50px;
  text-decoration: none;
  font-weight: bold;
  padding: 10px 20px;
  background: none;
  border: none;
  transition: all 0.3s ease;
}

.home-button a:hover {
  background-color: rgba(255, 255, 255, 0.2);
  border-color: #61dafb;
}

/* Navigation Button Styles */
nav ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

nav ul li {
  margin: 20px 0;
}

nav ul li a {
  color: white;
  font-size: 20px;
  text-decoration: none;
  font-weight: bold;
  padding: 15px 30px;
  background-color: rgba(0, 0, 0, 0.5);
  border: 2px solid white;
  border-radius: 5px;
  transition: all 0.3s ease;
  display: block;
  width: 100%;
  max-width: 300px; /* Optional: Set a max width to keep the buttons from being too wide */
}

nav ul li a:hover {
  background-color: rgba(0, 0, 0, 1);
  border-color: #01ff38;
}

/* Footer Styles */
.footer-overlay {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Dark background with opacity */
  padding: 15px 0;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.footer-content {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 20px;
}

.footer-links {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px; /* Add some space below the links */
}

.footer-links a {
  color: white;
  margin: 0 15px; /* Spacing between links */
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
  transition: all 0.3s ease;
}

.footer-links a:hover {
  color: #00ff37;
}

.contract-address {
  display: flex;
  align-items: center;
  margin-top: 10px; /* Add spacing between links and the contract address */
}

.contract-address span {
  margin-right: 10px;
}

.contract-box {
  display: flex;
  align-items: center;
}

.contract-box input {
  padding: 8px 12px;
  font-size: 14px;
  margin-right: 10px;
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: transparent;
  color: white;
}

.contract-box button {
  padding: 8px 15px;
  font-size: 14px;
  background-color: #06b815;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.contract-box button:hover {
  background-color: #0a6302;
}

.contract-box button:focus {
  outline: none;
}

/* Mobile Adjustments */
@media (max-width: 600px) {
  .home-button a {
    font-size: 18px;
    padding: 8px 15px;
  }

  nav ul li a {
    font-size: 18px;
    padding: 12px 25px;
  }

  .footer-links a {
    font-size: 14px;
    margin: 0 10px;
  }

  .contract-box input {
    width: 250px;
  }

  .contract-box button {
    font-size: 12px;
    padding: 6px 12px;
  }
}
